import React, { useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";

import { DesktopContext } from "../../../App";
//---- MUI -------------------------//
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

//---- MUI STYLED COMPONENTS -------------------//
import FormDivider from "../../../components/divider/FormDivider";
import SubmitButton from "../../../components/submit/SubmitButtonNoGrid";
//---- MUI ICONS -------------------//

//---- CUSTOM HOOKS ----------------//

//---- 3RD PARTY -------------------//
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  yupMortgageAmount,
  yupMortgageAmount2,
  yupLoanTerm,
  yupLoanTerm2,
  yupInterestRate,
  yupInterestRate2,
} from "../../../config/yupSchema";

//---- COMPONENTS ------------------//
import {
  MortgageAmount,
  LoanTermText,
  InterestRate,
  MortgageType,
  MortgageAmount2,
  LoanTermText2,
  InterestRate2,
  MortgageType2,
} from "../../../formGroups/PropertyDetailsGroup";

//---- EXPORT DEFAULT FUNCTION -----//
export default function CalculatorInput({
  onSubmit,
  setCompare,
  compare,
  setFormStep,
}) {

  const screenWidth = useMediaQuery((theme) => theme.breakpoints.down("md")); // Modify the breakpoint value as needed

  const desktop = useContext(DesktopContext);
  //--- PROPS ------------------------//
  //eslint-disable-next-line
  CalculatorInput.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setCompare: PropTypes.func.isRequired,
    compare: PropTypes.bool.isRequired,
    setFormStep: PropTypes.func.isRequired,
  };
  //---- FUNCTIONS -------------------//

  //--- SETTERS ----------------------//
  //eslint-disable-next-line
  const compareButtonWidth = "60%";
  const secondDividerRef = useRef(null);
  const firstDividerRef = useRef(null);

  //--- CONSTANTS --------------------//

  let gridSpacing = screenWidth ? 0 : 6;
  //if (desktop) {
   // gridSpacing = 6;
  //}

  let schema;

  if (compare) {
    schema = yup.object().shape({
      mortgageAmount: yupMortgageAmount,
      mortgageAmount2: yupMortgageAmount2,
      loanTermText: yupLoanTerm,
      loanTermText2: yupLoanTerm2,
      interestRate: yupInterestRate,
      interestRate2: yupInterestRate2,
    });
  } else {
    schema = yup.object().shape({
      mortgageAmount: yupMortgageAmount,
      loanTermText: yupLoanTerm,
      interestRate: yupInterestRate,
    });
  }

  //--- HANDLERS ---------------------//
  const handleCompare = () => {
    setCompare(!compare);
    setFormStep(0);

    // Check if the ref and the current field is available
    if (secondDividerRef && !compare) {
      // Wait for state update (setCompare) to complete
      setTimeout(() => {
        //console.log(secondDividerRef);
        const offset = desktop ? 200 : 0; // Use 100px offset for desktop, 0 for others
        const offsetTop = secondDividerRef.current.getBoundingClientRect().top + window.scrollY - offset; 
        window.scroll({
          top: offsetTop,
          behavior: "smooth"
        });
      }, 0);
    }
    
    
  };

  const handleUncompare = () => {
    setCompare(!compare);
    setFormStep(0);
    window.scrollTo(0, 0);
  };

  const {
    handleSubmit,

    control,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  //--- CUSTOM HOOKS & USE EFFECT ----//
  //--- JSX --------------------------//

  return (
    <>
      <form>
        <FormDivider
          dividerText={compare ? "Mortgage 1" : "Mortgage Calculator"}
        />

        <Grid container spacing={gridSpacing} sx={{ mb: 1.35 }}>
          <Grid item md={6} xs={12}>
            <MortgageAmount control={control} required={true} />
            <InterestRate control={control} required={true} />
          </Grid>
          <Grid item md={6} xs={12}>
            <LoanTermText control={control} required={true} />
            <MortgageType control={control} required={true} />
            {!compare && (
              <Box display="flex" justifyContent="flex-end" marginTop={-1}>
                <Button
                  variant="outlined"
                  onClick={handleCompare}
                  sx={{
                    width: { compareButtonWidth },
                    marginTop: "12px",
                  }}
                >
                  Compare two mortgages
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>

        {!compare && (
          <>
            <div ref={firstDividerRef}></div>
            <Fade in={!compare}>
              <div>
                <SubmitButton
                  buttonText={"Calculate repayments"}
                  buttonFooterText={""}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  isSubmitting={isSubmitting}
                />
              </div>
            </Fade>
          </>
        )}

        {compare && (
          <>
            <div ref={secondDividerRef}></div>
            <Fade in={compare}>
              <div>
                <FormDivider dividerText={"Mortgage 2"} />
                <Grid container spacing={gridSpacing} sx={{ mb: 1.35 }}>
                  <Grid item md={6} xs={12}>
                    <MortgageAmount2 control={control} required={true} />
                    <InterestRate2 control={control} required={true} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <LoanTermText2 control={control} required={true} />
                    <MortgageType2
                      control={control}
                      required={true}
                      fieldName="mortgageAmount"
                    />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginTop={-1}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleUncompare}
                        sx={{
                          width: { compareButtonWidth },
                          marginTop: "12px",
                        }}
                      >
                        Remove second mortgage
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <SubmitButton
                  buttonText={"Calculate repayments"}
                  buttonFooterText={""}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  isSubmitting={isSubmitting}
                />
              </div>
            </Fade>
          </>
        )}
      </form>
    </>
  );
}
