import React, { useState, useRef, useContext } from "react";
import { createBrowserHistory } from "history";

//---- MUI -------------------------//
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { DesktopContext } from "../../App";

//---- MUI ICONS -------------------//
//---- CUSTOM HOOKS ----------------//
//---- FUNCTIONS -------------------//
//---- 3RD PARTY -------------------//
//---- COMPONENTS ------------------//

import CalculatorInput from "./formParts/CalculatorInput";
//import ResultsSingle from "./formParts/ResultsSingle";
//import ResultsDouble from "./formParts/ResultsDouble";
const ResultsSingle = React.lazy(() => import("./formParts/ResultsSingle"));
const ResultsDouble = React.lazy(() => import("./formParts/ResultsDouble"));

const data = {};

export default function MortgageCost() {

  const desktop = useContext(DesktopContext);
  const componentRef = useRef();

  //--- PROPS ------------------------//
  //--- SETTERS ----------------------//
  const onSubmit = (data) => {
    // Iterate over each field in the form data
    for (let field in data) {
      // Check if the value of the field is a string (we don't want to run replace on non-strings)
      if (typeof data[field] === "string") {
        // Check if the string contains only digits and commas
        if (/^[\d,]+$/.test(data[field])) {
          // Remove commas from the field value
          data[field] = data[field].replace(/,/g, "");
        }
      }
    }

    // Pass the cleaned data to HandleData
    HandleData(data);
  };

  const [formStep, setFormStep] = useState(0);
  const [checked, setChecked] = useState(true);
  const [settedData, setData] = useState({});
  const [showField, setShowField] = useState(1);
  const [compare, setCompare] = useState(false);

  //--- CONSTANTS --------------------//
  const customHistory = createBrowserHistory();

  //--- HANDLERS ---------------------//

  function HandleData(data) {
    setChecked((prev) => !prev);

    setData(data);

    //console.log(data);
    //console.log(settedData);
    //console.log(data.mortgageType);
    customHistory.push({ data });

    setFormStep(1);

    if (componentRef.current) {
      setTimeout(() => {
        const offset = desktop ? 125 : 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = componentRef.current.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;
  
        window.scroll({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, 300);
    }


    //const element = document.getElementById("root");
    //if (element) {
    //  element.scrollIntoView({ behavior: "smooth" });
    //}

    //if (firstDividerRef && compare) {
    // Wait for state update (setCompare) to complete

    //}
  }

  function HandleEditCalculations() {
    setFormStep(0);
    const element = document.getElementById("root");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  function GoBack() {
    window.history.back();
    const element = document.getElementById("root");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
  if (formStep === 0) {
    window.onpopstate = GoBack;
  }

  if (formStep === 1) {
    window.onpopstate = HandleEditCalculations;
  }

  //--- CUSTOM HOOKS & USE EFFECT ----//

  //--- JSX --------------------------//

  return (
    <>
      <Box sx={{width:"100%", mb:2}}>
        <CalculatorInput
          compare={compare}
          setCompare={setCompare}
          setFormStep={setFormStep}
          onSubmit={onSubmit}
          checked={checked}
          setShowField={setShowField}
          showField={showField}
        />
        <div ref={componentRef}></div>

        {formStep === 1 && !compare && (
          <>
            <React.Suspense fallback={<div>Loading...</div>}>
              <ResultsSingle
                settedData={settedData}
                HandleEditCalculations={HandleEditCalculations}
                showField={showField}
                mortgageType={settedData.mortgageType}
              />
            </React.Suspense>
          </>
        )}

        {formStep === 1 && compare && (
          <Grid item xs={12} md={6}>
            <React.Suspense fallback={<div>Loading...</div>}>
              <ResultsDouble
                settedData={settedData}
                data={data}
                HandleEditCalculations={HandleEditCalculations}
                showField={showField}
                mortgageType={settedData.mortgageType}
              />
            </React.Suspense>
          </Grid>
        )}
      </Box>
    </>
  );
}
